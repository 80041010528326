export const BUDGET_FRIENDLY_MIN_PRICE = 0
export const BUDGET_FRIENDLY_MAX_PRICE = 200

export const isBudgetFriendlySelected = (minPrice, maxPrice) => {
  return minPrice === BUDGET_FRIENDLY_MIN_PRICE && maxPrice === BUDGET_FRIENDLY_MAX_PRICE
}

export const isThanksgivingBanner = (experimentKey, userCountryCode) => {
  if (!experimentKey || !userCountryCode) return false

  return experimentKey.split('|').includes(userCountryCode)
}
