import dayjs from '@/lib/useDates'
import { US_THANKSGIVING_DATES, CANADA_THANKSGIVING_DATES } from '@/constants'

// Sort an array of Moment dates
export function sortMomentDateRange(dateRange = []) {
  if (!dateRange.length) {
    return
  }

  if (dateRange.some((date) => !date?.isValid())) {
    return
  }

  return dateRange.sort(function (a, b) {
    return a.unix() - b.unix()
  })
}

// Day of Week - https://day.js.org/docs/en/get-set/day
export const selectMidweekDates = (start, end) => {
  if (!start || !end) {
    const startResult = dayjs().add(1, 'week').day(1).format('YYYY-MM-DD')

    return {
      start: startResult,
      end: dayjs(startResult).add(4, 'day').format('YYYY-MM-DD')
    }
  }

  const sunday = 0
  const saturday = 6
  const weekend = [sunday, saturday]
  const startWeekday = Number(dayjs(start).format('d'))
  const endWeekday = Number(dayjs(end).format('d'))
  const startDate = dayjs(start)
  const endDate = dayjs(end)

  let isFuture = true

  const diff = Math.abs(startDate.diff(endDate, 'day'))

  // Big ranges get the last midweek
  if (diff >= 7) {
    const start = endDate.day(1).format('YYYY-MM-DD')
    return { start, end: dayjs(start).add(4, 'day').format('YYYY-MM-DD') }
  }

  // Start as a saturday cannot select the future
  // Or start as a weekday and end as a weekend cannot select the future
  if (startWeekday === saturday || (!weekend.includes(startWeekday) && weekend.includes(endWeekday))) {
    isFuture = false
  }

  let startResult = dayjs(start)
    .add(isFuture ? 1 : 0, 'week')
    .day(1)
    .format('YYYY-MM-DD')

  // Avoid selecting disabled dates
  if (dayjs(startResult).isBefore(dayjs())) {
    startResult = dayjs().add(1, 'week').day(1).format('YYYY-MM-DD')
  }

  return {
    start: startResult,
    end: dayjs(startResult).add(4, 'day').format('YYYY-MM-DD')
  }
}

export const isMidweekSelected = (start, end) => {
  if (!start || !end) return false

  const monday = 1
  const startWeekday = Number(dayjs(start).format('d'))
  const diff = dayjs(end).diff(dayjs(start), 'day')

  return startWeekday === monday && diff === 4
}

export const selectThanksgivingDates = (isUnitedStates) => {
  const start = isUnitedStates ? US_THANKSGIVING_DATES.start : CANADA_THANKSGIVING_DATES.start
  const end = isUnitedStates ? US_THANKSGIVING_DATES.end : CANADA_THANKSGIVING_DATES.end

  return { start, end }
}

export const isThanksgivingSelected = (isUnitedStates, start, end) => {
  return isUnitedStates
    ? start === US_THANKSGIVING_DATES.start && end === US_THANKSGIVING_DATES.end
    : start === CANADA_THANKSGIVING_DATES.start && end === CANADA_THANKSGIVING_DATES.end
}
